import React, { useState, CSSProperties, ReactElement, useEffect } from "react";
import "../assets/styles/components/sht_header_style.css";
import Logo from "../../assets/images/showtime_logo.png";
import { RiMenu3Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import SHTMobileNav from "./SHTMobileNav";
import store from "../store";
import { connect } from "react-redux";
import * as actions from "../store/actions";

interface MenuItemProps {
  label: string;
  link: string;
  newsReducer?: any;
}

const MenuItem: React.FC<MenuItemProps> = ({ label, link, newsReducer }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [active, setActive] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleSetActive = () => {
    setActive(true);

    // Deactivate previously active items
    const allMenuItems = document.querySelectorAll(".menu-item");
    allMenuItems.forEach((item: any) => {
      if (item !== link) {
        localStorage.setItem(item, JSON.stringify(false));
      }
    });

    localStorage.setItem(link, JSON.stringify(true));
  };

  useEffect(() => {
    // Check local storage for saved active state on component mount
    const savedActiveState = localStorage.getItem(link);
    if (savedActiveState) {
      setActive(JSON.parse(savedActiveState));
    }
  }, [link]);

  const menuItemStyle: CSSProperties = {
    margin: "0 10px",
    textDecoration: "none",
    padding: "10px",
    cursor: "pointer",
    fontSize: "13px",
    color: `${
      /* active ? "#1B48E0" :  */ isHovered
        ? "#1B48E0"
        : newsReducer.mode == "light"
        ? "#000000"
        : "#ffffff"
    }`,
    fontWeight: 400,
    transition: "color 0.3s ease",
  };

  return (
    <Link to={link}>
      <div
        className="menu-item"
        style={menuItemStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleSetActive}
      >
        {label}
      </div>
    </Link>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(MenuItem);

