import React, { useState, CSSProperties, ReactElement } from "react";
import "../assets/styles/components/sht_header_style.css";
import Logo from "../assets/images/showtime_logo.png";
import { IoMdClose } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import ProfileDropDown from "./ProfileDropDown";
import SHTButton from "./SHTButton";

interface MenuItemProps {
  label: string;
  link: string;
}

interface headerProps {
  handleClose: any;
  auth_token: any
}

const MenuItem: React.FC<MenuItemProps> = ({ label, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const menuItemStyle: CSSProperties = {
    margin: "0 10px",
    textDecoration: "none",
    padding: "10px",
    cursor: "pointer",
    fontSize: "17px",
    color: `${isHovered ? "#1B48E0" : "#ffffff"}`,
    fontWeight: 600,
    transition: "border-color 0.3s ease",
  };

  return (
    <Link to={link}>
      <div
        style={menuItemStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {label}
      </div>
    </Link>
  );
};

const SHTMobileNav = (props: headerProps): ReactElement => {
  const { handleClose, auth_token } = props;
  const navigate = useNavigate();
  return (
    <header className="nav_container">
      <nav className="mobile_header">
        <div className="d-flex align-items-center justify-content-between px-5">
          <div>
            <img src={Logo} alt="Logo" className="logo" width="50px" />
          </div>
          <div onClick={handleClose}>
            <IoMdClose color="white" />
          </div>
        </div>
        <div className="menu_mobile_items">
          <MenuItem label="HOME" link="/" />
          <MenuItem label="ABOUT" link="/about" />
          <MenuItem label="MEDIA AND CONTENT" link="/media_and_content_creator_guidelines" />
          <MenuItem label="SCORES" link="/scores" />
          <MenuItem label="SCHEDULES" link="/league/schedule" />
          <MenuItem label="TEAMS " link="/teams" />
          <MenuItem label="STATS" link="/stats" />
          <MenuItem label="STANDINGS" link="/standings" />
        </div>

        <div className="mobile_profile">
          {auth_token ? (
            <ProfileDropDown />
          ) : (
            <div>
              <div>
                <SHTButton
                  type="submit"
                  name="Login"
                  color="primary"
                  size="sm"
                  handleClick={() => navigate("/login")}
                />
              </div>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default SHTMobileNav;
